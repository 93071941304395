import DataCycleInit from '../../../vendor/gems/data-cycle-core/app/assets/javascripts/application';
import appSignalInit from 'datacycle-appsignal-adapter';

switch (import.meta.env.MODE) {
	case "production":
		appSignalInit("683298e9-f5e5-40ff-9ecb-7c3cc216c417");
		break;
	case "staging":
		appSignalInit("311d61e4-3022-417d-9a6e-e3f0cdfefe62");
		break;
}

DataCycleInit({ remoteRenderFull: true }, () => {
	if (window.appSignal)
		DataCycle.notifications.addEventListener("error", ({ detail }) =>
			appSignal.sendError(detail),
		);
});
